import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Media from 'components/media';
import CharAnimation, { titleAnimationInitial } from 'animations/charAnimation';
import PropTypes from 'prop-types';

const PostHeroContainer = styled.div`
  .heroImage {
    margin: 0 auto;
    width: 100%;
    position: relative;

    .gatsby-image-wrapper div:first-child {
      padding-bottom: 100vh !important;
    }
  }

  .heroTitle {
    color: white;
    font-size: 4rem;

    text-align: left;
    position: absolute;
    right: 0;
    bottom: 4.5rem;
    right: 2rem;
    z-index: 30;
    transform: translateY(40px);

    .year {
      display: inline-block;
      font-size: calc(4rem * 0.275);
      transform: translateY(calc(-60% - 4rem * 0.6));
      font-weight: normal;
    }
  }

  @media (max-width: 700px) {
    .heroTitle {
      font-size: 2.5rem;
      bottom: 5rem;
      right: 0.75rem;
      bottom: 4rem;

      .year {
        font-size: calc(2.5rem * 0.275);
        transform: translateY(calc(-60% - 2.5rem * 0.6));
      }
    }
  }

  @media (max-width: 500px) {
    .heroTitle {
      font-size: 2rem;

      .year {
        font-size: calc(2rem * 0.275);
        transform: translateY(calc(-60% - 2rem * 0.6));
      }
    }
  }
`;

const PostHero = ({ hero, title, year }) => {
  let titleRefs = [];

  titleRefs.push(useRef(null));

  useEffect(() => {
    titleAnimationInitial(0, titleRefs, -1);
  }, []);

  return (
    <PostHeroContainer>
      <div className="heroImage">
        <Media
          videoCheck={hero.file.url.slice(-3)}
          videoSrcURL={hero.file.url}
          fluid={hero.fluid}
          alt={hero.title}
          title={hero.title}
          description={hero.description}
        />
      </div>
      <div className="heroTitle">
        <CharAnimation text={title} titleRefs={titleRefs} ele={0} year={year} />
      </div>
    </PostHeroContainer>
  );
};

PostHero.propTypes = {
  hero: PropTypes.object,
  title: PropTypes.string,
  year: PropTypes.string,
};

export default PostHero;
