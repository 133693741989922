import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ScrollingTitle from 'components/scrollingTitle';
import Media from 'components/media';
import SlidingButton from 'components/slidingButton';
import PropTypes from 'prop-types';

const PostArticlesContainer = styled.div`
  display: flex;
  padding-bottom: ${props => (props.missingAll ? '15vh' : '15vh')};

  .awardsContainer,
  .articlesContainer,
  .quoteContainer {
    width: calc(100% / 3);
    pointer-events: none;
  }

  .titleContainer {
    width: 50%;
    pointer-events: auto;
  }

  .awardTitle,
  .articleBody p {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  a:hover {
    color: #ed2c3e;
  }

  @media (max-width: 700px) {
    .titleContainer {
      // width: 75%;
      width: calc(100% - 1.5rem - 16px);
    }
  }

  @media (max-width: 550px) {
    .awardTitle,
    .articleBody p {
      font-size: 1.1rem;
    }
  }
`;

const ArticlesContainer = styled.div`
  padding-top: ${props => (props.empty ? '0vh !important' : '10vh')};
  ${props => (props.missingQuoteOnly ? 'padding-top: 22vh;' : null)}
  transform: ${props =>
    props.missingAwardOnly ? 'translateX(-50%)' : 'translateX(0)'};
  ${props => (props.missingQuoteOnly ? 'transform: translateX(100%);' : null)}
  pointer-events: ${props => (props.empty ? 'none' : 'auto')};
  .articleBody p {
    padding: 0;
    margin: 0;
  }

  .articleBody {
    pointer-events: auto;
  }

  .articleBody p:first-child {
    padding-top: 40px;
  }

  @media (max-width: 950px) {
    transform: translateX(-50%);
  }

  @media (max-width: 550px) {
    transform: translateX(calc(-100% + 1.5rem + 16px));
  }
`;

const AwardsContainer = styled.div`
  transform: translateX(50%);
  padding-top: ${props => (props.empty ? '0vh !important' : '50vh')};
  ${props => (props.missingQuoteOnly ? 'padding-top: 12vh;' : null)}
  pointer-events: ${props => (props.empty ? 'none' : 'auto')};

   ul {
     pointer-events: auto;
   }

  .award {
    padding: 15px 0;
  }

  .award,
  .awardTitle,
  .year,
  .awardBody p,
  .titleContainer {
    pointer-events: auto;
  }

  .award:first-child {
    padding-top: 40px;
  }

  .year {
    font-weight: 500;
    // font-family: sans-serif;
    display: inline-block;
    font-size: calc(2rem * 0.3);
    transform: translate(0.2rem, calc(-2rem * 0.7));
  }

  .awardBody p {
    padding: 0;
    margin-bottom: 5px;
    // white-space: nowrap;
  }

  @media (max-width: 950px) {
    padding-top: 85vh;
    transform: translateX(25%);
  }

  @media (max-width: 700px) {
    padding-top: 80vh;

    .award {
      width: 250%;
    }
  }

  @media (max-width: 550px) {
    padding-top: 100vh;
    transform: translateX(calc(1.5rem + 16px));

    .year {
    font-size: calc(2rem * 0.3);
    transform: translate(0.2rem, calc(-1.3rem * 0.7));
  }
  }
`;

const QuoteContainer = styled.div`
  margin-top: ${props => (props.empty ? '0vh !important' : '33vh')};
  ${props => (props.missingAwardOnly ? 'margin-top: 23vh;' : null)}
  ${props => (props.missingAwardAndArticles ? 'margin-top: 8vh;' : null)}

  padding-bottom: ${props => (props.empty ? '0px' : '13vw')};
  ${props =>
    props.missingAwardAndArticles ? 'padding-bottom: calc(160px + 5vw);' : null}
  
  transform: translateX(-50%);
  ${props =>
    props.missingAwardAndArticles ? 'transform: translateX(-150%);' : null}

  pointer-events: ${props => (props.empty ? 'none' : 'auto')};

  position: relative;

  .imageContainer {
    opacity: 0.6;
  }

  .gatsby-image-wrapper div:first-child {
    padding-bottom: 80% !important;
  }

  .quoteBody {
    width: 50%;
    position: absolute;
    left: 75%;
    top: 12vw;
    pointer-events: auto;
  }

  .buttonContainer {
    padding-top: 40px;
    width: 105px;
  }

  p {
    font-size: 2vw;
    // font-family: serif;
    margin: 0;
    padding: 0;
    line-height: 1.3;
    font-weight: 600;
  }

  .quote {
    position: relative;
  }

  .quote::after {
    content: '${props =>
      props.quoteSource ? '— ' + props.quoteSource.quoteSource : null}';
    position: absolute;
    top: 104%;
    z-index: 10;
    font-size: 0.9vw;
    color: white;
    left: 79%;
    white-space: nowrap;
  }

  @media (max-width: 1300px) {
    .quote::after {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 950px) {

    transform: translateX(-75%);
    margin-top: 40vh;

    .gatsby-image-wrapper {
      width: 150%;
    }

    .quoteBody {
      left: calc(75% + 10px);
      width: 59%;
      min-width: 150px;
      top: 25vw;
    }

    p {
      font-size: 1.18rem;
    }
    
  }

  @media (max-width: 700px) {

    transform: translateX(-100%);

    ${props =>
      props.missingAwardAndArticles ? 'margin-top: 12vh;' : 'margin-top: 36vh;'}
  }

  @media (max-width: 550px) {
    margin-top: 50vh;

    ${props =>
      props.missingAwardAndArticles ? 'margin-top: 12vh;' : 'margin-top: 40vh;'}
    transform: translateX(calc(-200% + 1.5rem + 16px));

    p {
      font-size: 1rem;
    }

    .gatsby-image-wrapper {
      width: calc(300% - 3rem - 32px);
    }

    .quoteBody {
      top: 46vw;
      left: 150%;
    }

    .quote::after {
      top: 104%;
      left: 60%;
    }
  }

  @media (max-width: 450px) {
      .quoteBody {
      left: calc(75% + 10px);
    }
  }
`;

const PostArticles = ({
  articles,
  award,
  quote,
  quoteLink,
  quoteSource,
  quoteImage,
}) => {
  const [missingAll, setMissingAll] = useState(false);
  const [missingAwardOnly, setMissingAwardOnly] = useState(false);
  const [missingQuoteOnly, setMissingQuoteOnly] = useState(false);
  const [missingAwardAndArticles, setMissingAwardAndArticles] = useState(false);

  useEffect(() => {
    if (!award && !quote && !articles) {
      setMissingAll(true);
    }

    if (!award && quote && articles) {
      setMissingAwardOnly(true);
    }

    if (award && !quote && articles) {
      setMissingQuoteOnly(true);
    }

    if (!award && !articles && quote) {
      setMissingAwardAndArticles(true);
    }
  }, []);

  return (
    <PostArticlesContainer missingAll={missingAll}>
      {award ? (
        <AwardsContainer
          className="awardsContainer"
          missingQuoteOnly={missingQuoteOnly}
        >
          <div data-scroll data-scroll-speed="2.5">
            <ScrollingTitle title={'Awards'} />
            <ul>
              {award.map((ele, i) => {
                return (
                  <li key={i} className="award">
                    <a
                      href={ele.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3 className="awardTitle">
                        {ele.title}
                        <span className="year">{ele.year}</span>
                      </h3>
                    </a>
                    <div
                      className="awardBody"
                      dangerouslySetInnerHTML={{
                        __html: ele.body.childMarkdownRemark.html,
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </AwardsContainer>
      ) : (
        <AwardsContainer className="awardsContainer" empty={true} />
      )}

      {articles ? (
        <ArticlesContainer
          className="articlesContainer"
          missingAwardOnly={missingAwardOnly}
          missingQuoteOnly={missingQuoteOnly}
        >
          <div data-scroll data-scroll-speed="1">
            <ScrollingTitle title={'Articles'} />
            <div
              className="articleBody"
              dangerouslySetInnerHTML={{
                __html: articles.childMarkdownRemark.html,
              }}
            />
          </div>
        </ArticlesContainer>
      ) : (
        <ArticlesContainer className="articlesContainer" empty={true} />
      )}

      {quote && quoteImage && quoteLink && quoteSource ? (
        <QuoteContainer
          className="quoteContainer"
          quoteSource={quoteSource}
          missingAwardOnly={missingAwardOnly}
          missingAwardAndArticles={missingAwardAndArticles}
        >
          <div data-scroll data-scroll-speed="4">
            <Media
              videoCheck={quoteImage.file.url.slice(-3)}
              videoSrcURL={quoteImage.file.url}
              fluid={quoteImage.fluid}
              alt={quoteImage.title}
              title={quoteImage.title}
              description={quoteImage.description}
            />

            <div className="quoteBody">
              <div
                className="quote"
                dangerouslySetInnerHTML={{
                  __html: quote.childMarkdownRemark.html,
                }}
              />
              <div className="buttonContainer">
                <a href={quoteLink} rel="noopener noreferrer" target="_blank">
                  <SlidingButton title={'Full Review'} />
                </a>
              </div>
            </div>
          </div>
        </QuoteContainer>
      ) : (
        <QuoteContainer className="quoteContainer" empty={true} />
      )}
    </PostArticlesContainer>
  );
};

PostArticles.propTypes = {
  articles: PropTypes.object,
  award: PropTypes.array,
  quote: PropTypes.object,
  quoteLink: PropTypes.string,
  quoteSource: PropTypes.object,
  quoteImage: PropTypes.object,
};

export default PostArticles;
