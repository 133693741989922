import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import PropTypes from 'prop-types';

const TextContainer = styled.div`
  opacity: 0;

  .char {
    transform: translateX(-120%);
    pointer-events: auto;
  }

  .charContainer {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
`;

const CharAnimation = ({ text, titleRefs, ele, year }) => {
  let refContainer = useRef(null);

  useEffect(() => {
    gsap.to(refContainer, 0.1, { autoAlpha: 1, delay: 0.2 });

    return () => {};
  }, []);

  const wrap = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    wrapper.setAttribute('class', 'charContainer');
  };

  useEffect(() => {
    const myElement = new SplitText(refContainer, {
      type: 'chars, words',
      charsClass: 'char char++',
      wordsClass: 'word word++',
    });

    setTitleRefs(titleRefs, ele, myElement.chars);

    for (const char of titleRefs[ele]) {
      wrap(char, document.createElement('span'));
    }
  });

  return (
    <>
      <TextContainer
        ref={div => (refContainer = div)}
        className={'textAnimationContainer'}
      >
        {text}
        {/* &nbsp; */}
        {year ? <span className="year">{year}</span> : null}
      </TextContainer>
    </>
  );
};

export const slideIn = (refs, direction) => {
  const tl = gsap.timeline();
  gsap.set(refs, { autoAlpha: 1 });

  if (direction === 1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '-120%' },
      {
        x: '0',
      }
    );
  }

  if (direction === -1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '120%' },
      {
        x: '0',
      }
    );
  }
};

export const slideOut = (refs, direction) => {
  const tl = gsap.timeline();

  if (direction === 1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '0' },
      {
        x: '120%',
      }
    );
  }

  if (direction === -1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '0' },
      {
        x: '-120%',
      }
    );
  }
};

export const titleAnimation = (title, titleRefs, direction) => {
  for (let i = 0; i < titleRefs.length; i++) {
    if (
      titleRefs[i][0].style.transform !== 'translate(120%, 0px)' &&
      titleRefs[i][0].style.transform !== 'translate(-120%, 0px)' &&
      titleRefs[i][0].style.transform !== 'translate(-120%)' &&
      titleRefs[i][0].style.transform !== 'translate(120%)'
    ) {
      slideOut(titleRefs[i], direction);
    }
  }

  setTimeout(() => {
    slideIn(titleRefs[title], direction);
  }, 400);
};

export const titleAnimationInitial = (title, titleRefs, direction) => {
  for (let i = 0; i < titleRefs.length; i++) {
    if (
      titleRefs[i][0].style.transform !== 'translate(120%, 0px)' &&
      titleRefs[i][0].style.transform !== 'translate(-120%, 0px)' &&
      titleRefs[i][0].style.transform !== 'translate(-120%)' &&
      titleRefs[i][0].style.transform !== 'translate(120%)'
    ) {
      gsap.set(titleRefs[i], { autoAlpha: 0 });
      slideOut(titleRefs[i], direction);
    }
  }
  gsap.set(titleRefs[title], { autoAlpha: 1 });
  slideIn(titleRefs[title], direction);
};

export const setTitleRefs = (titleRefs, ele, arr) => {
  titleRefs[ele] = arr;
};

CharAnimation.propTypes = {
  text: PropTypes.string,
  titleRefs: PropTypes.array,
  ele: PropTypes.number.isRequired,
  year: PropTypes.string,
};

export default CharAnimation;
