import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Media from 'components/media';
import gsap from 'gsap';
import { Draggable, InertiaPlugin } from 'gsap/all';
import { slideDuration } from 'constants/animation';
import PropTypes from 'prop-types';

const PostSlideshowContainer = styled.div`
  min-height: 33vw;
  overflow: hidden;

  .slidesOuter {
    position: relative;
  }

  .slidesInner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .slide {
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .slideContent {
    position: relative;
    width: 100%;
  }

  .gatsby-image-wrapper div:first-child {
    padding-bottom: 66% !important;
  }

  .slideLeft,
  .slideRight {
    cursor: pointer;
    position: absolute;
    z-index: 10;

    top: 0;
    height: 33vw;
    background-color: rgba(1, 1, 1, 0.5);

    border: none;
    padding: 0;
  }

  .slideLeft {
    left: 0;
    width: calc(100% / 3);
  }

  .slideRight {
    right: 0;
    width: calc(100% / 6);
  }

  @media (max-width: 950px) {
    min-height: 50vw;

    .gatsby-image-wrapper div:first-child {
      padding-bottom: 100% !important;
      height: 50vw;
    }

    .slideLeft,
    .slideRight {
      height: 50vw;
    }
  }

  @media (max-width: 650px) {
    min-height: 66.5vw;

    .slidesInner .slide {
      width: calc(100% * 2 / 3);
    }

    .slideLeft,
    .slideRight {
      width: calc(100% / 6);
      height: calc(66.5vw + 1px);
    }
  }
`;

const PostSlideshow = ({ images }) => {
  let slidesOuterRef = useRef(null);
  let slideRefs = [];
  let slideContentRefs = [];

  images.forEach(image => {
    slideRefs.push(useRef(null));
    slideContentRefs.push(useRef(null));
  });

  const wrapPartial = (min, max) => {
    var r = max - min;

    return function(value) {
      var v = value - min;

      return ((r + (v % r)) % r) + min;
    };
  };

  const wrap = wrapPartial(-200, (slideRefs.length - 2) * 100);

  const setPosition = below => {
    for (let i = 0; i < slideRefs.length; i++) {
      gsap.set(slideRefs[i], {
        xPercent: below ? i * 100 + 25 : i * 100 + (100 * 2) / 3,
        modifiers: {
          xPercent: wrap,
        },
      });
    }
  };

  const handleSwitchPosition = mediaQuery => {
    if (mediaQuery.matches) {
      setPosition(true);
      updateAnimation();
    } else {
      setPosition(false);
      updateAnimation();
    }
  };

  // LEFT SLIDESHOW / DRAGGABLE

  let dragInstance = useRef(null);
  let proxyRef = useRef();

  let transform = useRef(null);

  let animation = useRef(null);
  let slideWidth;
  let wrapWidth;
  let numSlides = slideRefs.length;
  let slideAnimation = gsap.to({}, 0.1, {});

  const animateSlides = direction => {
    slideAnimation.kill();

    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const x = snapX(xVal + direction * slideWidth);

    slideAnimation = gsap.to(proxyRef.current, slideDuration, {
      x: x,
      onUpdate: updateProgress,
    });
  };

  // let currentLocation = useRef(0);

  // const animateImages = (timeline, direction) => {};

  // const animateImagesInitial = (timeline, direction) => {};

  const snapX = x => {
    return Math.round(x / slideWidth) * slideWidth;
  };

  const updateProgress = () => {
    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const xPercentage = xVal / wrapWidth;

    const trans =
      xPercentage < 0 ? Math.ceil(xPercentage * -1) + xPercentage : xPercentage;

    animation.current.progress(trans);
  };

  const resize = () => {
    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const norm = xVal / wrapWidth || 0;

    slideWidth = slideRefs[0].getBoundingClientRect().width;
    wrapWidth = slideWidth * numSlides;

    gsap.set(proxyRef.current, {
      x: norm * wrapWidth,
    });

    animateSlides(0);
    slideAnimation.progress(1);
  };

  const handleSlide = direction => {
    // Right == 1, Left  == -1

    // const tl = gsap.timeline();
    animateSlides(-direction);
    // animateImages(tl, direction);
  };

  const updateAnimation = () => {
    animation.current = gsap.to(slideRefs, 0.8, {
      xPercent: '+=' + numSlides * 100,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrap,
      },
    });
  };

  useEffect(() => {
    proxyRef.current = document.createElement('div');
    gsap.set(proxyRef.current, {
      x: '+=0',
    });
    transform.current = proxyRef.current._gsap;

    gsap.registerPlugin(Draggable, InertiaPlugin);

    // setPosition();

    // SET POSITION

    const switchPosition = window.matchMedia('(max-width: 650px)');
    handleSwitchPosition(switchPosition);
    switchPosition.addListener(handleSwitchPosition);

    dragInstance.current = Draggable.create(proxyRef.current, {
      trigger: slidesOuterRef,
      type: 'x',
      // edgeResistance: 0.65,
      inertia: true,
      onPress: function() {
        slideAnimation.kill();
        this.update();
      },
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        x: snapX,
      },
    });

    animation.current = gsap.to(slideRefs, 0.8, {
      xPercent: '+=' + numSlides * 100,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrap,
      },
    });

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
      switchPosition.removeListener(handleSwitchPosition);
    };
  }, []);

  return (
    <PostSlideshowContainer>
      <div
        className="slidesOuter"
        id="slidesOuter"
        ref={div => {
          slidesOuterRef = div;
        }}
      >
        <ul className="slidesInner">
          {images.map((image, i) => {
            return (
              <li
                key={i}
                className="slide"
                ref={li => {
                  slideRefs[i] = li;
                }}
              >
                <div
                  className="slideContent"
                  ref={div => {
                    slideContentRefs[i] = div;
                  }}
                >
                  <Media
                    videoCheck={image.file.url.slice(-3)}
                    videoSrcURL={image.file.url}
                    fluid={image.fluid}
                    alt={image.title}
                    title={image.title}
                    description={image.description}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <button
          className="slideLeft"
          onClick={() => {
            handleSlide(-1);
          }}
        ></button>
        <button
          className="slideRight"
          onClick={() => {
            handleSlide(1);
          }}
        ></button>
      </div>
    </PostSlideshowContainer>
  );
};

PostSlideshow.propTypes = {
  images: PropTypes.array,
};

export default PostSlideshow;
