import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';

const PostInfoContainer = styled.div`
  display: flex;
  padding-top: 4rem;

  .headlineDescriptionContainer {
    width: calc(100% * 2 / 3);
    display: flex;
  }

  .headline,
  .description {
    width: calc(100% * 1 / 4);
    transform: translateX(100%);
  }

  .crewShareContainer {
    width: calc(100% / 3);
    display: flex;
  }

  .crew,
  .share {
    width: 50%;
  }

  .headline p {
    font-size: 1.4rem;
    line-height: 1;
  }

  .crew {
    p {
      margin-bottom: 0;
      font-size: 0.85rem;
    }
    p:last-child {
      margin-top: 2rem;
    }
  }

  .shareSub {
    display: flex;
    flex-direction: column;
    color: white;
    width: 16px;
    margin-left: 10px;

    .iconContainer {
      width: 100%;
      margin-top: 30px;
    }

    a:first-child .iconContainer {
      margin-top: 0;
    }

    .svg-inline--fa {
      width: 100%;
      pointer-events: auto;
    }
  }

  @media (max-width: 950px) {
    .headline,
    .description {
      width: calc(50%);
      transform: translateX(0);
    }

    .crewShareContainer {
      transform: translateX(0);
    }

    .crew {
      width: calc((100%) - 50px);
    }

    .share {
      width: 50px;
    }
  }

  @media (max-width: 700px) {
    justify-content: space-between;

    .headlineDescriptionContainer {
      flex-direction: column;
      width: calc(100% * 2 / 3);
      padding-left: calc(25% / 4);
    }

    .headline,
    .description {
      width: 100%;
    }

    .crewShareContainer {
      flex-direction: column;
    }

    .crew {
      width: 100%;
    }

    .share {
      width: 100%;
      padding-left: 10px;
      padding-top: 2rem;
    }

    .shareSub {
      flex-direction: row;
      width: 100%;
      margin-left: 0;

      .iconContainer {
        pointer-events: auto;
        margin: 0;
        width: 16px;
        margin-right: 30px;
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;

    .headlineDescriptionContainer,
    .crewShareContainer {
      width: calc(100% * 5 / 6);
      padding-left: calc(1.5rem + 16px);
    }

    .headline,
    .description,
    .crew {
      p {
        padding-left: 0;
      }
    }

    .share {
      padding-left: 0;
    }
  }
`;

const PostInfo = ({ headline, crew, description, title, slug }) => {
  const uriTitle = encodeURIComponent(title.trim());

  const emailLink = `mailto:?subject=${title}&body=https://www.stellarcitizens.com/${slug}`;

  return (
    <PostInfoContainer>
      <div className="headlineDescriptionContainer">
        <div
          className="headline"
          dangerouslySetInnerHTML={{
            __html: headline.childMarkdownRemark.html,
          }}
        />
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      </div>
      <div className="crewShareContainer">
        <div
          className="crew"
          dangerouslySetInnerHTML={{
            __html: crew.childMarkdownRemark.html,
          }}
        />
        <div className="share">
          <div className="shareSub">
            <a
              className="twitter-share-button"
              href={`https://twitter.com/intent/tweet?text=${uriTitle}&url=https://stellarcitizens.com/${slug}/`}
              target="_blank"
              rel="canonical noopener noreferrer"
            >
              <div className="iconContainer">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
            </a>

            <a
              className="fb-share-button"
              href={`https://www.facebook.com/sharer/sharer.php?u=https://stellarcitizens.com/${slug}/`}
              target="_blank"
              rel="canonical noopener noreferrer"
            >
              <div className="iconContainer">
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
            </a>

            <a href={emailLink} title="Share by Email">
              <div className="iconContainer">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </PostInfoContainer>
  );
};

PostInfo.propTypes = {
  headline: PropTypes.object,
  crew: PropTypes.object,
  description: PropTypes.object,
  title: PropTypes.string,
  slug: PropTypes.string,
};

export default PostInfo;
