import React from 'react';
import styled from 'styled-components';
import TriangleWhite from 'images/triangleWhite.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const PostNavigatorContainer = styled.div`
  width: calc(100% * 2 / 3);
  margin: 0 auto;
  display: flex;
  padding-top: ${props => (props.galleryMedia ? '15vh' : '0vh')};
  padding-bottom: 10vh;

  .prev,
  .next {
    width: 50%;
  }

  .postNavText {
    width: calc(100%);
    display: flex;
    justify-content: center;
  }

  h2 {
    font-size: 4rem;
  }

  .triangleContainer {
    width: 30px;

    .cls-1 {
      fill: #fff;
    }
  }

  .left {
    transform: rotate(90deg);
    margin: 0 40px 0 0;
  }

  .right {
    transform: rotate(-90deg);
    margin: 0 0 0 40px;
  }

  @media (max-width: 700px) {
    h2 {
      font-size: 3rem;
    }

    .triangleContainer {
      width: 20px;
    }

    .left {
      margin: 0 30px 0 0;
    }

    .right {
      margin: 0 0 0 30px;
    }
  }

  @media (max-width: 550px) {
    justify-content: space-between;

    .prev .pageNavLink {
      justify-content: flex-start;
    }

    .next .pageNavLink {
      justify-content: flex-end;
    }

    h2 {
      font-size: 1.7rem;
    }

    .triangleContainer {
      width: 15px;
    }

    .left {
      margin: 0 20px 0 0;
    }

    .right {
      margin: 0 0 0 20px;
    }
  }
`;

const PostNavigator = ({ next, previous, galleryMedia }) => {
  return (
    <PostNavigatorContainer
      galleryMedia={galleryMedia && galleryMedia.length > 4 ? true : false}
    >
      <div className="prev">
        <Link to={`/${previous}/`} className="pageNavLink">
          <div className="postNavText">
            <div className="triangleContainer left">
              <TriangleWhite className={'white'} />
            </div>

            <h2>Prev</h2>
          </div>
        </Link>
      </div>
      <div className="next">
        <Link to={`/${next}/`} className="pageNavLink">
          <div className="postNavText">
            <h2>Next</h2>

            <div className="triangleContainer right">
              <TriangleWhite className={'white'} />
            </div>
          </div>
        </Link>
      </div>
    </PostNavigatorContainer>
  );
};

PostNavigator.propTypes = {
  next: PropTypes.string,
  previous: PropTypes.string,
  galleryMedia: PropTypes.array,
};

export default PostNavigator;
