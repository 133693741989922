import React from 'react';
import styled from 'styled-components';
import TriangleWhite from 'images/triangleWhite.svg';
import PropTypes from 'prop-types';

const SlidingButtonContainer = styled.button`
  overflow: hidden;
  position: relative;

  .buttonTop {
    height: 35px;
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    color: #1c1c20;
    transition: all 0.4s;
    font-size: 0.65rem;
    font-weight: 700;
    transform: translate(10px, 1px);
  }

  .triangleContainer {
    width: 10px;
    position: relative;
    transition: all 0.4s;
    transform: translate(-10px, 0px) rotate(-90deg);

    .white {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .cls-1 {
      transition: all 0.4s;
      fill: #1c1c20;
    }
  }

  .buttonBottom {
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    position: absolute;
    transform: scaleY(1);
    transform-origin: bottom center;
    transition: transform 0.4s;
  }

  &:hover {
    .buttonBottom {
      transform: scaleY(0.025);
    }

    .button {
      color: white;
      transform: translate(0px, 1px);
    }

    .cls-1 {
      fill: white;
    }

    .triangleContainer {
      transform: translate(0px, 0px) rotate(-90deg);
    }
  }
`;

const SlidingButton = ({ title }) => {
  return (
    <SlidingButtonContainer>
      <div className="buttonTop">
        <div className="button">{title}</div>
        <div className="triangleContainer">
          <TriangleWhite className={'white'} />
        </div>
      </div>
      <div className="buttonBottom"></div>
    </SlidingButtonContainer>
  );
};

SlidingButton.propTypes = {
  title: PropTypes.string,
};

export default SlidingButton;
