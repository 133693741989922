import React, { useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Cursor from 'components/cursor';
import PostHero from 'components/postHero';
import PostInfo from 'components/postInfo';
import PostArticles from 'components/postArticles';
import PostSlideshow from 'components/postSlideshow';
import PostNavigator from 'components/postNavigator';
import Footer from 'components/footer';
import PropTypes from 'prop-types';
import gsap from 'gsap';

const PostTemplate = ({ data, location }) => {
  const {
    title,
    slug,
    description,
    hero,
    year,
    headline,
    crew,
    articles,
    award,
    quote,
    quoteLink,
    quoteSource,
    quoteImage,
    galleryMedia,
  } = data.contentfulPost;

  let previous = null;
  let next = null;

  data.allContentfulPost.edges.forEach(post => {
    if (post.node.slug === slug) {
      post.previous
        ? (previous = post.previous.slug)
        : (previous =
            data.allContentfulPost.edges[
              data.allContentfulPost.edges.length - 1
            ].node.slug);

      post.next
        ? (next = post.next.slug)
        : (next = data.allContentfulPost.edges[0].node.slug);
    }
  });

  let segments = useRef(6);

  let scroll;

  const handleScroll = (destination, offset, time) => {
    scroll.scrollTo(destination, offset, time);
  };

  useEffect(() => {
    gsap.to('#linesContainer', 3, { scaleY: 1 });

    gsap.to(
      '.hamburgerMenuContainer, .logoContainer, .icons, .triangleContainer, .heroTimerContainer',
      1,
      {
        autoAlpha: 1,
      }
    );

    import('locomotive-scroll').then(locomotiveModule => {
      scroll = new locomotiveModule.default(
        {
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          getSpeed: true,
          getDirection: true,
          useKeyboard: true,
          inertia: 0.7,
          smoothMobile: true,
          touchMultiplier: 2.5,
        },
        []
      );

      setTimeout(() => {
        scroll.update();
      }, 300);

      window.addEventListener('resize', scroll.update());
    });

    return () => {
      window.removeEventListener('resize', scroll.update());
      scroll.destroy();
    };
  }, []);

  return (
    <Layout data={data} location={location} handleScroll={handleScroll}>
      <Cursor />
      <div data-scroll-container>
        <section data-scroll-section>
          <PostHero segments={segments} hero={hero} title={title} year={year} />
        </section>
        <section data-scroll-section>
          <PostInfo
            headline={headline}
            description={description}
            crew={crew}
            title={title}
            slug={slug}
          />
        </section>
        <section data-scroll-section>
          <PostArticles
            articles={articles}
            award={award}
            quote={quote}
            quoteLink={quoteLink}
            quoteSource={quoteSource}
            quoteImage={quoteImage}
          />
        </section>
        {galleryMedia && galleryMedia.length > 4 ? (
          <section data-scroll-section>
            <PostSlideshow images={galleryMedia} />
          </section>
        ) : null}
        <section data-scroll-section>
          <PostNavigator
            next={next}
            previous={previous}
            galleryMedia={galleryMedia}
          />
        </section>

        <Footer data={data.contentfulMotto} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String) {
    contentfulPost(slug: { eq: $slug }, node_locale: { eq: "en-US" }) {
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      crew {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      featuredMedia {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        description
        file {
          url
        }
      }
      galleryMedia {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        title
        file {
          url
        }
      }
      headline {
        childMarkdownRemark {
          html
        }
      }
      hero {
        fluid(maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 1200) {
          ...GatsbyContentfulFixed_withWebp
        }
        title
        description
        file {
          url
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      quote {
        childMarkdownRemark {
          html
        }
      }
      quoteLink
      quoteSource {
        quoteSource
      }
      quoteImage {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        title
        file {
          url
        }
      }
      articles {
        childMarkdownRemark {
          html
        }
      }
      award {
        title
        year
        link
        body {
          childMarkdownRemark {
            html
          }
        }
      }
      slug
      title
      year
      id
      tag {
        slug
        title
      }
    }
    allContentfulPost(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        next {
          slug
        }
        previous {
          slug
        }
        node {
          id
          slug
          title
          year
          hero {
            file {
              url
            }
          }
          tag {
            title
          }
        }
      }
    }
    contentfulMotto {
      sideText1 {
        childMarkdownRemark {
          html
        }
      }
      sideText2 {
        childMarkdownRemark {
          html
        }
      }
      sideText3 {
        childMarkdownRemark {
          html
        }
      }
      motto {
        childMarkdownRemark {
          html
        }
        motto
      }
    }
    allContentfulTimeline(
      sort: { order: DESC, fields: date }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          title
          slug
          date
        }
      }
    }
  }
`;

PostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default PostTemplate;
